import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddaBlock, LazyDefaultPicture, LazyEndPageSection, LazyFixedBlock, LazyImageText, LazyIntroPagine, LazyListImageText, LazyMainButton, LazyOverlapBlock, LazyPrimoBloccoContenuti, LazySwiperPicture, LazySwiperSlideContent, LazySwiperSlider } from '#components'
const lazyGlobalComponents = [
  ["AddaBlock", LazyAddaBlock],
["DefaultPicture", LazyDefaultPicture],
["EndPageSection", LazyEndPageSection],
["FixedBlock", LazyFixedBlock],
["ImageText", LazyImageText],
["IntroPagine", LazyIntroPagine],
["ListImageText", LazyListImageText],
["MainButton", LazyMainButton],
["OverlapBlock", LazyOverlapBlock],
["PrimoBloccoContenuti", LazyPrimoBloccoContenuti],
["SwiperPicture", LazySwiperPicture],
["SwiperSlideContent", LazySwiperSlideContent],
["SwiperSlider", LazySwiperSlider],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
