<template>
  <div v-if="isLoading" :class="{ loader: true, hide: isHiding }">
    <img src="@/assets/loader/loader.gif" alt="DigitXS" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      isHiding: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.isHiding = true
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    }, 3800)
  },
}
</script>

<style lang="scss">
@import './style.scss';
</style>
