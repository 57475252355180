<template>
  <div class="adda-block row-40-20">
    <div class="container row-80-20 max">
      <h2 class="heading-2 adda-block-title">
        Metodo ADDA: l’approccio progettuale proprietario DigitXS
      </h2>
      <div class="container-flex-sections p-top-3 p-bot-3">
        <div class="left-section">
          <img
            src="@/assets/img/pagine/home/adda.png"
            alt="DigitXS"
            loading="lazy"
          />
        </div>
        <div class="right-section">
          <p class="text-2">
            Il metodo ideato da DigitXS fa dialogare strategia documentale e di
            processo con la cultura aziendale. Andiamo oltre l’applicazione di
            una soluzione a un problema: partiamo da audit approfonditi che
            indagano flussi e persone e modelliamo gli interventi in base alle
            vere necessità dell’azienda e del mercato di riferimento, portando a
            risultati misurabili.
          </p>
          <div class="section-button">
            <MainButton
              text="Metodo ADDA"
              url="/metodo-adda"
              :internal-link="true"
              :is-black="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddaBlock',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
