import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from 'lenis'

export const useLenis = () => {
  const lenis = ref(null)
  const isActive = ref(false)

  onMounted(() => {
    lenis.value = new Lenis()
    lenis.value.on('scroll', ScrollTrigger.update)
    window.lenis = lenis.value
    gsap.ticker.add((time) => {
      lenis.value.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)

    isActive.value = true
  })

  onUnmounted(() => {
    lenis.value.destroy()
    isActive.value = false
  })

  return { lenis, isActive }
}
